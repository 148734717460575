import { Controller } from "@hotwired/stimulus";
import { patch } from "@rails/request.js";

export default class extends Controller {
  static targets = [
    "couponCreationSuccessModal",
    "couponCreationFailedModal",
    "redeemButton",
  ];

  connect() {
    this.bodyTarget = document.querySelector("body");
  }

  async redeem(event) {
    const redeemUrl = event.currentTarget.dataset.redeemUrl;

    try {
      const response = await patch(redeemUrl);

      if (!response.ok) {
        throw new Error("Failed to redeem reward");
      }

      this.openSuccessModal();
      this._disableRedeemButton();
    } catch (error) {
      console.error(error.message);
      this.openFailedModal();
    }
  }

  _disableRedeemButton() {
    this.redeemButtonTarget.classList.add(
      "line-reward-inventory-detail__button"
    );
    this.redeemButtonTarget.classList.add(
      "line-reward-inventory-detail__button--disabled"
    );
    this.redeemButtonTarget.textContent = "แลกรางวัลแล้ว";
  }

  openSuccessModal() {
    this.couponCreationSuccessModalTarget.classList.remove(
      "line-reward-inventory-detail__noti-modal--hidden"
    );
    this.bodyTarget.classList.add("no-scroll");
  }

  closeSuccessModal() {
    this.couponCreationSuccessModalTarget.classList.add(
      "line-reward-inventory-detail__noti-modal--hidden"
    );
    this.bodyTarget.classList.remove("no-scroll");
  }

  openFailedModal() {
    this.couponCreationFailedModalTarget.classList.remove(
      "line-reward-inventory-detail__noti-modal--hidden"
    );
    this.bodyTarget.classList.add("no-scroll");
  }

  closeFailedModal() {
    this.couponCreationFailedModalTarget.classList.add(
      "line-reward-inventory-detail__noti-modal--hidden"
    );
    this.bodyTarget.classList.remove("no-scroll");
  }
}
