import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["transactionDetail", "arrow"];

  showDetail(event) {
    document
      .querySelectorAll(".line-point-card__arrow--rotate")
      .forEach((e) => {
        e.classList.remove("line-point-card__arrow--rotate");
      });
    const clickedTransactionDetail = this.transactionDetailTarget;
    const isCurrentlyShown = clickedTransactionDetail.classList.contains(
      "line-point-card__detail--show"
    );

    // Close other open transaction details
    this.closeOtherTransactionDetails(clickedTransactionDetail);

    if (isCurrentlyShown) {
      this.hideTransactionDetail(clickedTransactionDetail);
    } else {
      this.showTransactionDetail(clickedTransactionDetail);
    }
  }

  closeOtherTransactionDetails(clickedTransactionDetail) {
    this.arrowTarget.classList.remove("line-point-card__arrow--rotate");
    document
      .querySelectorAll(".line-point-card__detail--show")
      .forEach((transactionDetail) => {
        if (transactionDetail !== clickedTransactionDetail) {
          this.hideTransactionDetail(transactionDetail);
        }
      });
  }

  hideTransactionDetail(transactionDetail) {
    this.arrowTarget.classList.remove("line-point-card__arrow--rotate");
    const container = transactionDetail.querySelector(
      ".line-point-card__detail__container"
    );
    const currentHeight = container.scrollHeight;

    transactionDetail.style.height = `${currentHeight}px`; // Set height for transition

    setTimeout(() => {
      transactionDetail.classList.remove("line-point-card__detail--show");
      transactionDetail.classList.add("line-point-card__detail--hidden");
      transactionDetail.style.height = "0"; // Collapse height
    }, 10); // Match this duration to the CSS transition duration
  }

  showTransactionDetail(transactionDetail) {
    this.arrowTarget.classList.add("line-point-card__arrow--rotate");
    transactionDetail.style.display = "grid"; // Show the element
    transactionDetail.classList.remove("line-point-card__detail--hidden");
    transactionDetail.classList.add("line-point-card__detail--show");
    this.arrowTarget.classList.add("line-point-card__arrow");

    // Use requestAnimationFrame for better timing of the height transition
    requestAnimationFrame(() => {
      transactionDetail.style.height = ""; // Set height to the content's height

      const container = transactionDetail.querySelector(
        ".line-point-card__detail__container"
      );
      container.addEventListener(
        "transitionend",
        () => {
          transactionDetail.style.height = ""; // Reset height to auto after animation
        },
        { once: true }
      );
    });
  }
}
