import { Controller } from "@hotwired/stimulus";
import TurboRequest from "../helpers/turbo-request";
import { getUrlWithParams } from "../helpers/request-formatter";

export default class extends Controller {
  static targets = ["historicalField", "rawField", "skuField"];

  connect() {
    this.targetType = this.element.dataset.targetType;
    this.calculateMethod = this.element.dataset.calculateMethod;
    this.replaceProductListSelectionUrl =
      this.element.dataset.replaceProductListSelectionUrl;

    this._setProductFieldVisibility(!this._isDownlineOrBlankTargetType());
    this._setHistoricalFieldVisibility(this.calculateMethod);
  }

  selectTargetType(event) {
    const targetType = event.target.dataset.targetType;
    if (targetType === "ws_downline") {
      this._setProductFieldVisibility(false);
      return;
    }

    this._setProductFieldVisibility(true);
    const url = getUrlWithParams(this.replaceProductListSelectionUrl, [
      ["volume_target_type", targetType],
    ]);
    TurboRequest.get(url);
  }

  selectCalculateMethod(event) {
    const calculateMethod = event.target.dataset.calculateMethod;
    this._setHistoricalFieldVisibility(calculateMethod);
  }

  _setProductFieldVisibility(enabled) {
    if (enabled) {
      this.hasSkuFieldTarget && this.skuFieldTarget.classList.remove("hidden");
    } else {
      this.hasSkuFieldTarget && this.skuFieldTarget.classList.add("hidden");
    }
  }

  _setHistoricalFieldVisibility(calculateMethod) {
    if (calculateMethod === "historical" && calculateMethod !== "") {
      this.hasHistoricalFieldTarget &&
        this.historicalFieldTarget.classList.remove("hidden");

      this.hasRawFieldTarget && this.rawFieldTarget.classList.add("hidden");
    } else {
      this.hasHistoricalFieldTarget &&
        this.historicalFieldTarget.classList.add("hidden");

      this.hasRawFieldTarget && this.rawFieldTarget.classList.remove("hidden");
    }
  }

  _isDownlineOrBlankTargetType() {
    return this.targetType === "ws_downline" || this.targetType === "";
  }
}
