import { Controller } from "@hotwired/stimulus";
import TurboRequest from "../helpers/turbo-request";
import { getUrlWithParams } from "../helpers/request-formatter";

export default class extends Controller {
  static targets = ["form"];

  connect() {
    this.taskPath = this.element.dataset.taskPath;

    this.addTaskButtonUrl = this.element.dataset.addTaskButtonUrl;
    this.clearTaskSetFilterUrl = this.element.dataset.clearTaskSetFilterUrl;

    const element = document.querySelector(
      '[data-dropdown-input-target="text"]'
    );

    this.audienceTypeOldValue = null;
    if (element) {
      this.audienceTypeOldValue = element.innerHTML.trim().toLowerCase();
    }

    window.addEventListener(
      "beforeunload",
      this._clearTaskSetFilter.bind(this)
    );
  }

  disconnect() {
    this._clearTaskSetFilter();
  }

  disconnect() {
    this.clearTaskSetFilterUrl && TurboRequest.get(this.clearTaskSetFilterUrl);
  }

  confirmSubmitBeforeRedirectToTaskPage(event) {
    if (!this.hasFormTarget) {
      return;
    }

    const CONFIRM_MSG =
      "The task set will be automatically created. Do you want to proceed?";

    if (confirm(CONFIRM_MSG)) {
      const hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      if (this.clearTaskSetFilterUrl.includes("task_set_instances")) {
        hiddenField.setAttribute("name", "task_set_instance[redirect_to]");
      } else {
        hiddenField.setAttribute("name", "task_set_preset[redirect_to]");
      }
      hiddenField.setAttribute("value", this.taskPath);
      this.formTarget.appendChild(hiddenField);

      this.formTarget.submit();
    } else {
      event.preventDefault();
    }
  }

  async selectAudienceType(event) {
    const audienceType = event.target.dataset.audienceType;
    const audienceTypeKey = event.target.dataset.audienceTypeKey;

    if (this.audienceTypeOldValue === audienceTypeKey) {
      return;
    }

    this.audienceTypeOldValue = audienceTypeKey;
    const buttonUrl = getUrlWithParams(this.addTaskButtonUrl, [
      ["audience_type", audienceType],
    ]);

    await TurboRequest.get(buttonUrl);

    this._clearTaskSetsList();
  }

  _clearTaskSetsList() {
    const taskSetsList = this.element.querySelector("#tasks_list");

    taskSetsList.innerHTML = "";
    if (taskSetsList.classList.contains("admin__taskset__task-list")) {
      taskSetsList.classList.replace(
        "admin__taskset__task-list",
        "admin__taskset__task-list--empty"
      );
    }
  }

  _clearTaskSetFilter() {
    if (this.clearTaskSetFilterUrl) {
      TurboRequest.get(this.clearTaskSetFilterUrl);
    }
    window.removeEventListener(
      "beforeunload",
      this._clearTaskSetFilter.bind(this)
    );
  }
}
