import { application } from "./application";
import { Multiselect } from "./multiselect";

import ChoiceAdderController from "./choice_adder_controller";
import ChoiceController from "./choice_controller";
import DateInputController from "./date_input_controller";
import DateTimeInputController from "./datetime_input_controller";
import DropdownInputController from "./dropdown_input_controller";
import FileInputController from "./file_input_controller";
import LiffController from "./liff_controller";
import MobileNavbarController from "./mobile_navbar_controller";
import PasswordInputController from "./password_input_controller";
import QuestionController from "./question_controller";
import QuestionCounterController from "./question_counter_controller";
import SearchInputController from "./search_input_controller";
import SidebarController from "./sidebar_controller";
import SliderInputController from "./slider_input_controller";
import SortableController from "./sortable_controller";
import TimeInputController from "./time_input_controller";
import TagConditionController from "./tag_condition_controller";
import InputChangeController from "./input_change_controller";
import AudienceController from "./audience_controller";
import PaginationController from "./pagination_controller";
import DropdownButtonController from "./dropdown_button_controller";
import ModalController from "./modal_controller";
import TaskSelectionController from "./task_selection_controller";
import CampaignController from "./campaign_controller";
import TaskSetSelectionController from "./task_set_selection_controller";
import CampaignFilterController from "./campaign_filter_controller";
import TaskSetController from "./task_set_controller";
import VolumeTargetController from "./volume_target_controller";
import LiffContentsController from "./liff/contents_controller";
import LiffLandingController from "./liff/landing_controller";
import LiffTaskSetController from "./liff/task_set_controller";
import RewardHistoryController from "./liff/reward_history_controller";
import CopyController from "./liff/copy_controller";
import RuammitrCouponConditionForm from "./ruammitr_coupon_condition_form_controller";
import FormModalController from "./liff/form_modal_controller";
import RewardModalController from "./liff/reward_modal_controller";
import RedeemableRewardRedemptionController from "./liff/redeemable_reward_redemption_controller";
import NotiModalController from "./liff/noti_modal_controller";
import PointAccordionController from "./liff/point_accordion_controller";

application.register("choice-adder", ChoiceAdderController);
application.register("choice", ChoiceController);
application.register("date-input", DateInputController);
application.register("datetime-input", DateTimeInputController);
application.register("dropdown-input", DropdownInputController);
application.register("file-input", FileInputController);
application.register("liff", LiffController);
application.register("mobile-navbar", MobileNavbarController);
application.register("password-input", PasswordInputController);
application.register("question", QuestionController);
application.register("question-counter", QuestionCounterController);
application.register("search-input", SearchInputController);
application.register("sidebar", SidebarController);
application.register("slider-input", SliderInputController);
application.register("sortable", SortableController);
application.register("time-input", TimeInputController);
application.register("multiselect", Multiselect);
application.register("tag-condition", TagConditionController);
application.register("input-change", InputChangeController);
application.register("audience", AudienceController);
application.register("pagination", PaginationController);
application.register("dropdown-button", DropdownButtonController);
application.register("modal", ModalController);
application.register("task-selection", TaskSelectionController);
application.register("campaign", CampaignController);
application.register("task-set-selection", TaskSetSelectionController);
application.register("campaign-filter", CampaignFilterController);
application.register("task-set", TaskSetController);
application.register("volume-target", VolumeTargetController);
application.register("liff-contents", LiffContentsController);
application.register("liff-landing", LiffLandingController);
application.register("liff-task-set", LiffTaskSetController);
application.register("reward-history", RewardHistoryController);
application.register("copy", CopyController);
application.register("form-modal", FormModalController);
application.register("reward-modal", RewardModalController);
application.register(
  "redeemable-reward-redemption",
  RedeemableRewardRedemptionController
);
application.register(
  "ruammitr-coupon-condition-form",
  RuammitrCouponConditionForm
);
application.register("noti-modal", NotiModalController);
application.register("point-accordion", PointAccordionController);
