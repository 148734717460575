import { Controller } from "@hotwired/stimulus";
import TurboRequest from "../helpers/turbo-request";
import { getUrlWithParams } from "../helpers/request-formatter";

export default class extends Controller {
  static targets = ["taskIds", "clearButton", "input"];

  connect() {
    this.taskListUrl = this.element.dataset.showTasksListUrl;
    this.selectTasksUrl = this.element.dataset.selectTasksUrl;
    this.isSelectAll = this.element.dataset.selectAll === "true";
    this.selectedItems = JSON.parse(this.element.dataset.selectedItems);
    this.page = this.element.dataset.page;
    this.toggleClearButton();

    this.taskIdsTargets.forEach((task) => {
      if (this.selectedItems.includes(parseInt(task.value))) {
        task.checked = true;
      }
    });
  }

  select(event) {
    const taskId = parseInt(event.target.value);
    const isChecked = event.target.checked;

    if (isChecked) {
      this.selectedItems.push(taskId);
    } else {
      this.selectedItems = this.selectedItems.filter((id) => id !== taskId);
    }

    const params = [];
    params.push(["selected_items", JSON.stringify(this.selectedItems)]);
    params.push(["page", this.page]);
    params.push[("q[name_i_cont]", this.inputTarget.value)];
    params.push(["select_all", "false"]);

    const url = getUrlWithParams(this.taskListUrl, params);

    TurboRequest.get(url);
  }

  selectAll(event) {
    const selectAllValue = event.target.checked ? "true" : "false";

    const url = getUrlWithParams(this.taskListUrl, [
      ["select_all", selectAllValue],
      ["page", this.page],
    ]);

    TurboRequest.get(url);
  }

  saveSelection() {
    const params = [];
    params.push(["selected_items", JSON.stringify(this.selectedItems)]);
    params.push(["page", this.page]);

    const url = getUrlWithParams(this.selectTasksUrl, params);

    TurboRequest.get(url);
  }

  toggleClearButton() {
    if (this.inputTarget.value !== "") {
      this.clearButtonTarget.classList.remove("hidden");
    } else {
      this.clearButtonTarget.classList.add("hidden");
    }
  }

  clearSearch() {
    this.inputTarget.value = "";
    this.clearButtonTarget.classList.add("hidden");
  }

  handleFormSubmit(event) {
    event.preventDefault();

    const url = getUrlWithParams(this.taskListUrl, [
      ["selected_items", JSON.stringify(this.selectedItems)],
      ["select_all", this.isSelectAll],
      ["q[name_i_cont]", this.inputTarget.value],
    ]);

    TurboRequest.get(url);
  }
}
