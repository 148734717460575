import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["loadMore"]

  connect() {
    window.addEventListener('scroll', (_) => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const totalHeight = document.documentElement.scrollHeight;
      if (scrollPosition >= totalHeight) {
        if (this.hasLoadMoreTarget) {
          this.loadMoreTarget.click();
        }
      }
    })
  }
}
