import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "input",
    "fileInfo",
    "fileName",
    "fileSize",
    "defaultValueInfo",
  ];

  connect() {
    // Prevent invalid drops by adding event listeners for dragover and drop events
    this.element.addEventListener(
      "dragover",
      this.preventInvalidDrop.bind(this)
    );
    this.element.addEventListener("drop", this.handleFileDrop.bind(this));
  }

  disconnect() {
    // Remove event listeners when the controller is disconnected
    this.element.removeEventListener(
      "dragover",
      this.preventInvalidDrop.bind(this)
    );
    this.element.removeEventListener("drop", this.handleFileDrop.bind(this));
  }

  showFileInfo(e) {
    if (e.target.value) {
      this.fileInfoTarget.classList.remove("hidden");
      const file = e.target.files[0];
      const fileName = file.name;
      const fileSize = (file.size / (1024 * 1024)).toFixed(2) + " MB";

      this.fileNameTarget.textContent = fileName;
      this.fileSizeTarget.textContent = fileSize;
    }
  }

  removeFile() {
    this.inputTarget.value = "";
    this.fileInfoTarget.classList.add("hidden");
  }

  removeDefaultValue() {
    this.defaultValueInfoTarget.classList.add("hidden");
    this.inputTarget.value = null;
  }

  preventInvalidDrop(event) {
    event.preventDefault(); // Prevent the default drag-and-drop behavior
    event.stopPropagation(); // Stop propagation to prevent unwanted effects
  }

  handleFileDrop(event) {
    event.preventDefault(); // Prevent the default drop behavior

    const allowedExtensions = this.inputTarget.accept
      .split(",") // Split the accept types into an array
      .map((type) => type.trim().toLowerCase()) // Clean up any whitespace and convert to lowercase
      .map((type) => (type.startsWith(".") ? type : "." + type)); // Ensure each type has a dot

    const files = event.dataTransfer.files; // Get dropped files
    for (const file of files) {
      const fileExtension = file.name.split(".").pop().toLowerCase(); // Extract the file extension
      if (!allowedExtensions.includes("." + fileExtension)) {
        alert("This file type is not allowed.");
        return;
      }
    }

    // If all files are valid, set the file input value
    const dataTransfer = new DataTransfer();
    for (const file of files) {
      dataTransfer.items.add(file);
    }
    this.inputTarget.files = dataTransfer.files; // Set the file input's files

    // Show file info after drop
    this.showFileInfo({ target: this.inputTarget });
  }
}
