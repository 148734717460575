import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["rewardModal"];

  openModal() {
    this.rewardModalTarget.classList.remove(
      "line-reward-inventory-detail__noti-modal--hidden"
    );
  }

  closeModal() {
    this.rewardModalTarget.classList.add(
      "line-reward-inventory-detail__noti-modal--hidden"
    );
  }
}
