import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["rewardFromActivity", "rewardFromPoint", "rewardFromActivityButton", "rewardFromPointButton"];

  showRewardFromActivity() {
    this.toggleActiveClass(this.rewardFromActivityButtonTarget, this.rewardFromPointButtonTarget);
    this.toggleVisibility(this.rewardFromActivityTarget, this.rewardFromPointTarget);
  }

  showRewardFromPoint() {
    this.toggleActiveClass(this.rewardFromPointButtonTarget, this.rewardFromActivityButtonTarget);
    this.toggleVisibility(this.rewardFromPointTarget, this.rewardFromActivityTarget);
  }

  toggleActiveClass(activeButton, inactiveButton) {
    activeButton.classList.add("line-reward-history-lists__button--selected");
    inactiveButton.classList.remove("line-reward-history-lists__button--selected");
  }

  toggleVisibility(showTarget, hideTarget) {
    showTarget.classList.remove("fade-out");
    showTarget.classList.add("fade-in");
    hideTarget.classList.remove("fade-in");
    hideTarget.classList.add("fade-out");

    setTimeout(() => {
      hideTarget.classList.add("line-reward-history-lists__body--hide");
      showTarget.classList.remove("line-reward-history-lists__body--hide");
    }, 300);
  }
}
