import { Controller } from "@hotwired/stimulus";
import { post, get } from "@rails/request.js";
import consumer from "../channels/consumer";

export default class extends Controller {
  static targets = [
    "testTag",
    "export",
    "cancel",
    "download",
    "audienceUserCount",
  ];

  connect() {
    this._preloadUi();

    this.channel = consumer.subscriptions.create(
      { channel: "JobStatusChannel" },
      {
        connected: () => {
          this.channel.send({ message: "Message from client" });
          console.debug("Connected to JobStatusChannel");
        },

        disconnected: () => {
          console.debug("Disconnected from JobStatusChannel");
        },

        received: (data) => {
          this._receivedData(data);
        },
      }
    );
  }

  disconnect() {
    if (this.channel) this.channel.unsubscribe();
  }

  async syncUsers() {
    const url = this.element.dataset.syncUserUrl;

    const audienceAndOperatorTags = document.getElementById(
      "audience_and_operator_tags"
    );
    const audienceIncludeOperatorTags = document.getElementById(
      "audience_include_operator_tags"
    );
    const audienceExcludeOperatorTags = document.getElementById(
      "audience_exclude_operator_tags"
    );

    const andOperatorTags = Array.from(
      audienceAndOperatorTags.selectedOptions
    ).map((option) => option.value);
    const includeOperatorTags = Array.from(
      audienceIncludeOperatorTags.selectedOptions
    ).map((option) => option.value);
    const excludeOperatorTags = Array.from(
      audienceExcludeOperatorTags.selectedOptions
    ).map((option) => option.value);

    this.disableTestTag();
    post(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        and_operator_tags: andOperatorTags,
        include_operator_tags: includeOperatorTags,
        exclude_operator_tags: excludeOperatorTags,
      }),
    })
      .catch((error) => {
        console.error(error);
        alert("Error syncing users");
      })
      .finally(() => {
        this.disableTestTag(false);
      });
  }

  exportUsers() {
    const url = this.element.dataset.exportUserUrl;
    get(url, {
      headers: {
        Accept: "application/json",
      },
    })
      .then(async (response) => {
        if (response.ok) {
          const data = await response.json;
          this.currentUserExportJobId = data.job_id;
          this._showAudienceUserButton("cancel");
        } else {
          alert("Error exporting users");
        }
      })
      .catch((error) => {
        console.error(error);
        alert("Error exporting users");
      });
  }

  cancelExportJob() {
    this._sendStatus(
      "cancelled",
      "audience_user_export_job",
      this.currentUserExportJobId
    );
    this._showAudienceUserButton("export");
  }

  async downloadExportedFile() {
    const download_url = this.downloadExportedFileUrl;
    window.open(download_url, "_blank");
  }

  disableTestTag(disabled = true) {
    if (disabled) {
      this.testTagTarget.innerHTML = "Loading...";
      this.testTagTarget.disabled = true;
    } else {
      this.testTagTarget.innerHTML = "Test Tags ▶";
      this.testTagTarget.disabled = false;
    }
  }

  selectConfigurationType(event) {
    event.preventDefault();
    const configurationType = event.target.dataset.configurationType;
    const audienceNameInput = document.querySelector(
      "input[name='audience[name]"
    );
    const audienceNoteInput = document.querySelector(
      "input[name='audience[note]"
    );
    const url = new URL(window.location.href);
    url.searchParams.set("configuration_type", configurationType);

    // persisting the audience name and note across page reload
    if (audienceNameInput && audienceNameInput.value) {
      url.searchParams.set("name", audienceNameInput.value);
    }

    if (audienceNoteInput && audienceNoteInput.value) {
      url.searchParams.set("note", audienceNoteInput.value);
    }

    Turbo.visit(url.toString());
  }

  _preloadUi() {
    this.currentUserExportJobId = this.element.dataset.userExportJobId;
    this.currentUserLoaderJobStatus = this.element.dataset.userLoaderJobStatus;
    this.currentUserExportJobStatus = this.element.dataset.userExportJobStatus;
    this.audienceUserCount = this.element.dataset.audienceUserCount;
    this.datamartUserCount = this.element.dataset.datamartUserCount;
    this.downloadExportedFileUrl = this.element.dataset.exportedUserFileUrl;

    // Show the audience user count if it's available (default Loading...)
    if (!!this.audienceUserCount) {
      this._setAudienceUserCountText(
        `${this.datamartUserCount} | CRM Users ${this.audienceUserCount}`
      );
    }

    // Show the appropriate button based on the current state
    // - If the user export job is done and the loader job is done, show the download button
    if (
      this.currentUserExportJobStatus === "done" &&
      this.currentUserLoaderJobStatus === "done" &&
      this.downloadExportedFileUrl
    ) {
      this._showAudienceUserButton("download");
    }
    // - If the user export job is running, show the cancel button
    else if (
      this.currentUserExportJobStatus === "running" &&
      this.currentUserLoaderJobStatus === "done"
    ) {
      this._showAudienceUserButton("cancel");
    }
    // - If the loader job is running, show the export button as disabled
    else if (this.currentUserLoaderJobStatus === "running") {
      this._showAudienceUserButton("export", { disabled: true });
    } else if (
      this.currentUserLoaderJobStatus === "failed" ||
      this.currentUserLoaderJobStatus === "cancelled"
    ) {
      this._setAudienceUserCountText("Loading failed");
      this._showAudienceUserButton("export", { disabled: true });
    }
    // - anything else, show the export button
    else {
      this._showAudienceUserButton("export");
    }
  }

  _receivedData(data) {
    console.debug("Received data", data);
    switch (data.status) {
      case "running":
        if (data.job_type === "audience_user_loader_job") {
          this._setAudienceUserCountText("Loading...");
          this._showAudienceUserButton("export", { disabled: true });
        } else if (data.job_type === "audience_user_export_job") {
          this._showAudienceUserButton("cancel");
        }
        break;
      case "done":
        if (data.job_type === "audience_user_loader_job") {
          const { audience_users_count, datamart_user_count } = data.params;
          this._setAudienceUserCountText(
            `${datamart_user_count} | CRM Users ${audience_users_count}`
          );
          this._showAudienceUserButton("export", { disabled: false });
        } else if (data.job_type === "audience_user_export_job") {
          this.downloadExportedFileUrl = data.params.file_url;
          this._showAudienceUserButton("download");
        }
        break;
      case "failed":
        if (data.job_type === "audience_user_loader_job") {
          this._setAudienceUserCountText("Loading failed");
          this._showAudienceUserButton("export", { disabled: true });
        }
        break;
      default:
        break;
    }
  }

  _sendStatus(status, jobType, jobId) {
    if (this.channel) {
      this.channel.send({
        status,
        job_type: jobType,
        job_id: jobId,
      });
    }
  }

  _showAudienceUserButton(name, { disabled = false } = {}) {
    if (this.hasExportTarget) {
      switch (name) {
        case "export":
          this.exportTarget.classList.remove("hidden");
          if (disabled) {
            this.exportTarget.disabled = true;
            this.exportTarget.classList.remove("btn-rounded");
            this.exportTarget.classList.add("btn-rounded--disabled");
          } else {
            this.exportTarget.disabled = false;
            this.exportTarget.classList.remove("btn-rounded--disabled");
            this.exportTarget.classList.add("btn-rounded");
          }

          this.cancelTarget.classList.add("hidden");
          this.downloadTarget.classList.add("hidden");
          break;
        case "download":
          this.exportTarget.classList.add("hidden");
          this.cancelTarget.classList.add("hidden");
          this.downloadTarget.classList.remove("hidden");
          break;
        case "cancel":
          this.exportTarget.classList.add("hidden");
          this.cancelTarget.classList.remove("hidden");
          this.downloadTarget.classList.add("hidden");
          break;
      }
    }
  }

  _setAudienceUserCountText(text) {
    if (this.hasAudienceUserCountTarget) {
      this.audienceUserCountTarget.innerHTML = text;
    }
  }
}
