import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["code"];

  copy() {
    const code = this.codeTarget.innerText;
    navigator.clipboard
      .writeText(code)
      .then(() => {
        alert("Coupon code copied: " + code);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  }
}
