import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["deleteCondition", "conditionPanel"];

  connect() {
    console.log("connected");
  }

  deleteCondition() {
    console.log("delete condition");
    if (confirm("Are you sure you want to remove this condition?")) {
      this.conditionPanelTarget.classList.add("hidden");
      this.deleteConditionTarget.value = true;
    }
  }
}
