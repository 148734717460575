import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["notiModal", "body"]

  closeModal() {
    this.notiModalTarget.classList.add("line-layout__modal--hidden")
    this.bodyTarget.classList.remove("no-scroll")
  }

  closeRewardModal() {
    this.notiModalTarget.classList.add("line-layout__reward-modal--hidden")
    this.bodyTarget.classList.remove("no-scroll")
  }
}
