import { Controller } from "@hotwired/stimulus";
import { post, patch } from "@rails/request.js";

export default class extends Controller {
  static targets = ["formModal", "button", "form"];

  connect() {
    this.hasFormModalTarget &&
      this.formTarget.addEventListener("submit", this.submit.bind(this));
  }

  openModal() {
    this.formModalTarget.classList.remove(
      "line-reward-inventory-detail__modal--hidden"
    );
  }

  closeModal() {
    this.formModalTarget.classList.add(
      "line-reward-inventory-detail__modal--hidden"
    );
  }

  async submit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const methodInput = e.target.querySelector("input[name='_method']");
    let response;
    if (methodInput) {
      response = await patch(e.target.action, {
        body: formData,
      });
    } else {
      response = await post(e.target.action, {
        body: formData,
      });
    }

    if (response.redirected) {
      window.location.assign(response.response.url);
      return;
    }

    if (response.statusCode === 422) {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = (await response.text).trim();
      const newElement = tempDiv.firstChild;
      this.formModalTarget.replaceWith(newElement);
      this.connect();
      alert("กรุณากรอกข้อมูลให้ถูกต้องและครบถ้วน");
      return;
    }
    alert("เกิดข้อผิดพลาด โปรดลองใหม่่อีกครั้ง");
  }
}
