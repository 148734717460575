import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["clearButton", "input"];

  connect() {
    this.toggleClearButton();
  }

  toggleClearButton() {
    if (this.inputTarget.value !== "") {
      this.clearButtonTarget.classList.remove("hidden");
    } else {
      this.clearButtonTarget.classList.add("hidden");
    }
  }

  clearSearch() {
    this.inputTarget.value = "";
    this.clearButtonTarget.classList.add("hidden");

    // Clear the query parameters from the URL
    const url = new URL(window.location);
    
    // Remove all query parameters
    url.search = '';

    // Update the URL without the query parameters
    window.history.replaceState({}, '', url); // Update the URL without refreshing the page

    // Reload the page
    window.location.reload(); // Refresh the page
  }
}
