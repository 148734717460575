import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["taskDetail"];

  showTask(event) {
    const clickedTaskDetail = this.taskDetailTarget;
    const isCurrentlyShown = clickedTaskDetail.classList.contains("line-task--show");

    // Close other open task details
    this.closeOtherTaskDetails(clickedTaskDetail);

    if (isCurrentlyShown) {
      this.hideTaskDetail(clickedTaskDetail);
    } else {
      this.showTaskDetail(clickedTaskDetail);
    }
  }

  closeOtherTaskDetails(clickedTaskDetail) {
    document.querySelectorAll(".line-task--show").forEach((taskDetail) => {
      if (taskDetail !== clickedTaskDetail) {
        this.hideTaskDetail(taskDetail);
      }
    });
  }

  hideTaskDetail(taskDetail) {
    const container = taskDetail.querySelector(".line-task__container");
    const currentHeight = container.scrollHeight;

    taskDetail.style.height = `${currentHeight}px`; // Set height for transition

    setTimeout(() => {
      taskDetail.classList.remove("line-task--show");
      taskDetail.classList.add("line-task--hidden");
      taskDetail.style.height = "0"; // Collapse height
    }, 10); // Match this duration to the CSS transition duration
  }

  showTaskDetail(taskDetail) {
    taskDetail.style.display = "block"; // Show the element
    taskDetail.classList.remove("line-task--hidden");
    taskDetail.classList.add("line-task--show");

    // Use requestAnimationFrame for better timing of the height transition
    requestAnimationFrame(() => {
      taskDetail.style.height = ""; // Set height to the content's height

      const container = taskDetail.querySelector(".line-task__container");
      container.addEventListener("transitionend", () => {
        taskDetail.style.height = ""; // Reset height to auto after animation
      }, { once: true });
    });
  }
}
