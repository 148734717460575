import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    new Glide(".line-landing__highlight__carousel", {
      type: 'carousel',
      startAt: 0,
      perView: 1,
      autoplay: 5000,
      peek: {
        before: 10,
        after: 38,
      },
    }).mount();
  }
}
